/* © Microsoft Corporation. All rights reserved. */
.timeline {
    display: flex;
    position: relative;
    padding: 3px 2px 0;

    &::before {
        content: ' ';
        display: block;
        border: 1px solid rgba(255, 255, 255, 0.1);
        position: absolute;
        top: -2px;
        left: 12px;
        right: 12px;
    }
}

.timelineContent {
    flex: 1;
    display: flex;
    overflow: hidden;
    min-width: 0;
    padding: 0 2px;
}

.timelineItem {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    height: 47px;
    width: 50px;
    line-height: 22px;
    font-size: 12px;
    text-align: center;
    user-select: none;

    & + & {
        margin-inline-start: 20px;
    }
}

.rainIcon {
    margin-bottom: 3px;
}

.scrollButton {
    width: 15px;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    padding-bottom: 5px;
    margin: 1px;
}

.scrollButtonDisable {
    opacity: 0.5;
    cursor: default;
}

.scrollButtonLeft {
    & img {
        transform: rotate(90deg);
    }
}

.scrollButtonRight {
    & img {
        transform: rotate(-90deg);
    }
}
