body, html {
    font-family: Segoe UI, Segoe WP, Arial, Sans-Serif;
    background: #FFFFFF;
    margin: 0;
    padding: 0;
    overflow: auto;
}

.header {
    min-height: 119px;

    @media screen and (max-width: 955px) {
        min-height: 175px;
    }
}

.headerContent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 701;
}
