/* © Microsoft Corporation. All rights reserved. */

.labelRow {
    position: absolute;
    width: max-content;
    text-align: center;
    font-size: 14px;
    line-height: 1.1;
    user-select: none;
    min-width: 60px;
    font-weight: 500;
    cursor: pointer;
}

.labelLight {
    color: white;
    text-shadow:
        -1px -1px 0 rgb(0 0 0 / 45%),
        1px -1px 0 rgb(0 0 0 / 45%),
        -1px 1px 0 rgb(0 0 0 / 45%),
        1px 1px 0 rgb(0 0 0 / 45%);
}

.labelDark {
    color: black;
    text-shadow:
        -1px -1px 0 rgb(255 255 255 / 45%),
        1px -1px 0 rgb(255 255 255 / 45%),
        -1px 1px 0 rgb(255 255 255 / 45%),
        1px 1px 0 rgb(255 255 255 / 45%);
}

.labelLink {
    pointer-events: auto;

    &:hover .labelRow {
        text-decoration: underline;
    }
}
