/* © Microsoft Corporation. All rights reserved. */

.header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 4px;
    margin-top: -6px;
    pointer-events: none;
    white-space: nowrap;
}

.firstLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.icon {
    max-width: 15px;
    margin-right: 5.5px;
}

.firstLineText {
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
}

.secondLine {
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.8);
}
