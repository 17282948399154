/* © Microsoft Corporation. All rights reserved. */

.alertPane {
    --alert-y: #f1c40e;
    --alert-o: #e67e22;
    --alert-r: #e74c3b;
    --alert-b: #3967e4;
}

.alertSummary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 22px;
    padding: 12px;
    color: #fff;
    cursor: pointer;

    &[data-level='Y'] {
        background: var(--alert-y);
        color: #79570e;
    }
    &[data-level='O'] {
        background: var(--alert-o);
    }
    &[data-level='R'] {
        background: var(--alert-r);
    }
    &[data-level='B'] {
        background: var(--alert-b);
    }
}

.textoverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
}

.content {
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 0;
    font-size: 14px;
}

.openMap {
    color: #fff;
    text-decoration: none;
    word-break: keep-all;
    white-space: nowrap;
    font-weight: 500;
    margin-inline-start: 12px;

    &:hover {
        opacity: 0.7;
    }
}

.summary {
    margin: 0 12px;
}

.expandArrow {
    transform: rotate(180deg);
}

.alertList {
    padding: 4px 8px 4px 16px;
    max-height: 64px;
    overflow: auto;

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
}

.alertItem {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    padding: 4px 0;

    &:hover {
        opacity: 0.7;
    }

    &::before {
        content: ' ';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-inline-end: 8px;
    }

    &[data-level='Y']::before {
        background: var(--alert-y);
    }
    &[data-level='O']::before {
        background: var(--alert-o);
    }
    &[data-level='R']::before {
        background: var(--alert-r);
    }
    &[data-level='B']::before {
        background: var(--alert-b);
    }
}

.alertTime {
    font-size: 12px;
    margin-inline-start: 8px;
}
