/* © Microsoft Corporation. All rights reserved. */

.locationNameSection {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    margin-top: 12px;
}
