.locationBarContainer {
    height: 48px;
    z-index: 105;
}

.locationBar {
    position: fixed;
    top: 119px;
    left: 0;
    width: 100%;
    z-index: 99;
    @media screen and (max-width: 955px) {
        top: 175px;
    }
}
.locationBarNotFixed {
    position: static;
}

.minimap {
    height: 270px;
}

.ads {
    height: fit-content;

    & iframe {
        position: absolute;
    }
}

.cnNews {
    min-height: 250px;
    height: fit-content;
}


.news {
    & h2[class*='stripeHeading'] {
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-transform: uppercase;
        color: #FFFFFF !important;
    }
}

.newsTitle {
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    height: 20px;
    font-weight: 600;
    padding-bottom: 12px;
}

.blockPlaceholder {
    width: 300px;
}
