/* © Microsoft Corporation. All rights reserved. */
.container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 20;
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
