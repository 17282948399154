/* © Microsoft Corporation. All rights reserved. */
.tabButtonRow {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    height: 24px;
    margin-inline-end: 15px;
}

.tabButton {
    display: flex;
    height: 24px;
    flex-direction: row;
    border: 0;
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
    background: transparent;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    gap: 4px;

    &.active {
        background: #ffd02c;
        color: #000;
    }

    &:hover &:not(.active) {
        background: rgba(255, 255, 255, 0.08);
    }

    &:focus {
        outline: none;
    }
}
