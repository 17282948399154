.headerRow {
    padding: 20px 36px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    min-height: 91px;
    display: flex;
    color: #fff;
    flex-direction: column;
    font-weight: normal;
    &:hover {
        background: rgba(255, 255, 255, 0.04);
    }
}

.title {
    display: flex;
    gap: 24px;
    font-size: 18px;
    line-height: 24px;
    align-items: center;
}

.summary {
    display: flex;
    gap: 12px;
    font-size: 14px;
    line-height: 19px;
    margin-top: 8px;
}

.tempLow {
    opacity: 0.7;
    font-weight: 400;
}

.temperature {
    display: flex;
    gap: 6px;
}

.icon {
    margin-inline-end: -16px;
}

.dayLabel {
    font-weight: 600;
}
