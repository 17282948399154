/* © Microsoft Corporation. All rights reserved. */

.label {
    color: white;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 20px;
    font-size: 14px;
    flex-direction: row;
    gap: 10px;
    word-break: keep-all;
    white-space: nowrap;
}

.input {
    cursor: pointer;
    height: 20px;
    width: 20px;
    transition: all 0.2s ease-in-out;
}
