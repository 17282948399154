/* © Microsoft Corporation. All rights reserved. */
.block {
    width: 100%;
}

.hide {
    display: none;
}

.title {
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    height: 20px;
    font-weight: 600;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & a {
        text-decoration: none;
        padding-right: 15px;
        color: #ffffff;
        &:hover {
            text-decoration: underline;
            color: #ffffff;
        }
    }
}
