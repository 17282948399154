/* © Microsoft Corporation. All rights reserved. */

.container {
    display: flex;
    flex-direction: row;
    padding: 12px;
    line-height: 22px;
    align-items: center;
}

.icon {
    height: fit-content;
    margin-inline-end: 5px;
    line-height: 0;
}

.text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
    font-size: 14px;
}

.mapLink {
    cursor: pointer;
    color: #8ed5ff;
    fill: #8ed5ff;
    font-weight: 500;
    text-decoration: none;
    margin-inline-start: 12px;
    font-size: 14px;

    &:hover {
        color: #6cb2dd;
    }
}
