/* © Microsoft Corporation. All rights reserved. */

.alertSection {
    display: block;
    position: relative;
    margin-inline-start: 32px;
}
.alertLink {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    line-height: 20px;
    font-size: 12px;
    text-decoration: none;
    text-wrap: nowrap;
    padding: 5px 12px;
    border-radius: 16px;
    cursor: pointer;

    background-color: #e67e22;
    color: #ffffff;
    &:hover {
        background-color: #e67e22;
        color: #ffffff;
        text-decoration: none;
    }

    &.alertTileBgB {
        background-color: #3967e4;
        &:hover {
            background-color: #3967e4;
        }
    }
    &.alertTileBgO {
        background-color: #e67e22;
        &:hover {
            background-color: #e67e22;
        }
    }
    &.alertTileBgR {
        background-color: #e74c3c;
        &:hover {
            background-color: #e74c3c;
        }
    }
    &.alertTileBgY {
        background-color: #ffd02c;
        color: #795700;
        &:hover {
            background-color: #ffd02c;
            color: #795700;
        }
    }
}
.alertIcon {
    margin-inline-end: 12px;
}
