/* © Microsoft Corporation. All rights reserved. */

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
}

.label {
    cursor: pointer;
    padding-left: 8px;
    color: #fff;
}

.input {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    outline: none;
    transition: all 0.2s ease-in-out;
}
