/* © Microsoft Corporation. All rights reserved. */

.pin {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 20;

    &:before {
        content: ' ';
        box-sizing: border-box;
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 3px solid #fff;
        box-shadow: 0px 0px 10px rgb(0 0 0 / 40%);
        background: #ffd02e;
    }
}
