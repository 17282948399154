/* © Microsoft Corporation. All rights reserved. */
.container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 6px;
    overflow: hidden;
}

.link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: pointer;
}
