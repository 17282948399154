/* © Microsoft Corporation. All rights reserved. */
.hourlyTable {
    height: 860px;
    margin-top: 8px;
    overflow-y: scroll;
    width: 100%;
    background: rgba(255, 255, 255, 0.08);

    color: #fff;

    &::-webkit-scrollbar {
        width: 14px;
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        border: solid 5px rgba(255, 255, 255, 0);
        border-radius: 6px;
        box-shadow: 5px 0 0 rgba(255, 255, 255, 0.3) inset;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
}