.hourlyDayTab {
    padding: 14px 12px;
    cursor: pointer;
    display: flex;
    transition: background-color 0.2s linear;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.03);
    min-width: 104px;
    border-radius: 6px;
    box-sizing: border-box;

    &:hover {
        background-color: rgba(255, 255, 255, 0.08);
    }

    &.selected {
        font-weight: 600;
        color: #fff;
        background-color: rgba(255, 255, 255, 0.08);
    }
}

.noUnderline {
    text-decoration: none;
}

.arrow::after {
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
    content: '';
    height: 7px;
    position: absolute;
    bottom: 16px;
    transform: translateY(-50%) rotate(45deg);
    width: 7px;

    [dir="rtl"] & {
        transform: translateY(-50%) rotate(-135deg);
    }
}

.label {
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    word-break: break-all;
    white-space: nowrap;
    text-transform: capitalize;
    margin-inline-end: 12px;
}
