.expandedInfo {
    opacity: 1;
    transition: opacity 0.1s ease;
    margin-inline-end: 15px;
}

.mainRow {
    display: flex;
    flex-direction: column;

    height: 75px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    padding: 20px 36px 20px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    cursor: pointer;
    line-height: 20px;
    font-size: 14px;
    font-family: inherit;
    color: #fff;

    background: transparent;
    transition: height ease 0.5s;

    &.expanded {
        height: unset;

        .expandedInfo {
            opacity: 1;
        }
    }

    &:hover {
        background: rgba(255, 255, 255, 0.04);
    }
}

.itemLabel {
    opacity: 0.6;
    overflow: hidden;
    text-transform: capitalize;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 82px;
}

.itemValue {
    font-weight: 400;
}

.rowItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 120px;
}

.row {
    display: flex;
    align-items: flex-start;
}

.middleRow {
    margin-top: 30px;
    flex-wrap: wrap;
}

.captureItem {
    width: 144px;
    min-width: 144px;
    max-width: 144px;
    margin-inline-end: 82px;
    white-space: nowrap;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}
.captureItemSmall {
    margin-inline-end: 0;
}

.timeItem {
    width: 60px;
    min-width: 60px;
    margin-inline-end: 20px;
    text-transform: uppercase;
}

.firstRowItem {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
}

.circleButton {
    width: 24px;
    height: 24px;
    &.down {
        transform: rotate(180deg);
    }
}

.rowContent {
    flex: 1;
    min-width: 0;
}


.rowInfo {
    display: flex;
    align-items: center;
}

.rowInfoItem {
    margin-inline-end: 16px;
    width: 100px;
    margin-bottom: 30px;
}

.firstRowItemSmall {
    width: 75px;
    margin-inline-end: 0;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}

.rowItemText {
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}