/* © Microsoft Corporation. All rights reserved. */

.container {
    position: relative;
    height: 100%;
}

.absoluteBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
}

.image {
    object-fit: cover;
    mask: linear-gradient(to bottom, #000 0%, transparent 100%);
    width: 100%;

    &.hide {
        display: none;
    }
}

.fixedBackground {
    position: fixed;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
}

.content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: stretch;
}
