/* © Microsoft Corporation. All rights reserved. */

.layoutContainer {
    flex: 1;
    min-height: 0;
}

.layout {
    --col-width: 300px;
    --col-gap: 10px;

    box-sizing: border-box;
    display: grid;
    grid-gap: var(--col-gap);
    grid-auto-flow: row dense;
    width: 100%;
    justify-content: center;
    justify-items: start;
    margin: 0 auto;
}

@media (max-width: 656px) {
    .layout {
        grid-template-columns: 1fr;
        width: 100%;
        padding: 0 10px;
    }
}

@media (min-width: 656px) {
    .layout {
        grid-template-columns: repeat(2, 1fr);
        width: calc(var(--col-width) * 2 + var(--col-gap));
    }
}

@media (min-width: 980px) {
    .layout {
        grid-template-columns: repeat(3, 1fr);
        width: calc(var(--col-width) * 3 + var(--col-gap) * 2);
    }
}

@media (min-width: 1304px) {
    .layout {
        grid-template-columns: repeat(4, 1fr);
        width: calc(var(--col-width) * 4 + var(--col-gap) * 3);
    }
}

@media (min-width: 1630px) {
    .layout.enable5 {
        grid-template-columns: repeat(5, 1fr);
        width: calc(var(--col-width) * 5 + var(--col-gap) * 4);
    }
}
