/* © Microsoft Corporation. All rights reserved. */

.hourlyChart {
    position: relative;
    min-width: 612px;
    width: 100%;
    height: 270px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 6px;
    backdrop-filter: blur(60px);
}

.hourlyChartHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 34px;
    padding: 16px 16px 10px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
}

.hourlyChartTabWrapper {
    position: relative;
    height: 24px;
    width: 0;
    flex: 1;
}

.hourlyChartHeaderInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    pointer-events: none;
}

.hourlyChartSummary {
    display: flex;
    flex-direction: row;
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    padding: 10px 16px 0;
}

.summaryText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}

.precipitationToggleButtons {
    display: inline-flex;
    flex-direction: row;
    gap: 10px;
}

.hourlyChartSummaryButton {
    align-items: flex-end;
    position: relative;
}

.chartWrapper {
    height: 174px;
    position: relative;
    padding-inline-end: 16px;
}

.svgChartWrapper {
    height: 174px;
    position: relative;
    padding-inline-end: 16px;
    padding-inline-start: 6px;
}

.dateSwitch {
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: space-between;
    width: 100%;
    bottom: 5px;
}

.spacer {
    flex: 1;
}

.dateSwitchButton {
    margin: 0 6px 0 6px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.08);
    display: flex;
    border: none;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    backdrop-filter: blur(5px);
    z-index: 3;
    padding: 0;

    &:focus {
        outline: none;
    }

    [dir="rtl"] & {
        transform: rotate(180deg);
    }
}

.rotate180 {
    transform: rotate(180deg);

    [dir="rtl"] & {
        transform: rotate(0deg);
    }
}
