.container {
    background: linear-gradient(180deg, rgba(219, 219, 219, 0.7) 20.83%, rgba(219, 219, 219, 0.7) 78.65%, rgba(219, 219, 219, 0) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0 36px;
    box-sizing: border-box;
}

.title {
    margin-top: 8px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
}

.desc {
    margin-top: 12px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
}
