/* © Microsoft Corporation. All rights reserved. */

.locationFullNameSection {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 612px;
    overflow: hidden;
}
.fullNameLink {
    color: #fff;
    cursor: pointer;
    line-height: 24px;
    font-weight: 400;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        color: #fff;
        text-decoration: underline;
        text-underline-position: under;
    }
}
.locationDropList {
    border: none;
    cursor: pointer;
    background: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    width: 16px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-start: 8px;
    margin-top: 4px;
}
.setHomeLocationIcon {
    border: 1px solid rgba(255, 255, 255, 0.8);
    cursor: pointer;
    background: none;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-inline-start: 16px;

    &:hover {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.8);
    }
}
.autoDetectedIcon {
    margin-inline-end: 12px;
}
.homeLocationIcon {
    margin-inline-end: 12px;
}
.chevronIcon {
    transform: rotate(90deg);
}
