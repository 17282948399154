/* © Microsoft Corporation. All rights reserved. */

.container {
    position: absolute;
    top: 12px;
    left: 12px;
    height: 40px;
    z-index: 20;
    display: flex;
}

.group {
    border-radius: 8px;
    backdrop-filter: blur(4px);
    padding: 2px 1px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    forced-color-adjust: none;

    & + & {
        margin-inline-start: 4px;
    }
}

.tooltip {
    position: absolute;
    top: 46px;
    left: 0;
    display: none;
    background-color: #ffd02c;
    color: #666666;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 18px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    text-transform: capitalize;

    &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 15px;
        border: 5px solid;
        border-color: transparent transparent #ffd02c transparent;
    }
}

.navButton {
    position: relative;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1px;

    &:hover .tooltip,
    &:focus .tooltip {
        display: block;
    }

    &:hover,
    &[data-active='1'] {
        background-color: #ffd02c;

        & img {
            filter: invert(0.7);
        }
    }
}
