/* © Microsoft Corporation. All rights reserved. */
.image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: none;
    object-position: center center;
    overflow: hidden;
    pointer-events: none;
    border-radius: initial;
}

.backgroundZ {
    z-index: 1;
}

.foregroundZ {
    z-index: 10;
}
