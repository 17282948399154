/* © Microsoft Corporation. All rights reserved. */

.panesContainer {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.pane {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.elementPane {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 11;
}

.elementTile {
    position: relative;
}

.tilesContainer {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    z-index: 2;

    [dir='rtl'] & {
        flex-direction: row-reverse;
    }
}

.tileImage {
    padding: 0;
    margin: 0;
    pointer-events: none;
}

.aniGroup {
    width: 100%;
    height: 100%;
}

.ani {
    --mmta-count: 1;
    --mmta-size: 0px;
    --mmta-duration: 1s;
    animation: tileLayerSwitch var(--mmta-duration)
        steps(var(--mmta-count)) infinite;
}

@keyframes tileLayerSwitch {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(
            0,
            var(--mmta-size)
        );
    }
}
