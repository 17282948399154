.weatherMobileUpsellRoot {
    display: flex;
    position: relative;
    margin-inline-start: auto;
    gap: 10px;
}
.weatherMobileUpsellContainer {
    display: flex;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    align-items: center;
    text-decoration: none;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.10);
    padding: 3px 4px;
    & > img {
        width: 16px;
        height: 16px;
    };
    & > span {
        height: 16px;
        white-space: nowrap;
        line-height: 16px;
        vertical-align: middle;
        margin-left: 4px;
    };
    &.showPopup {
        background: "var(--hover_background)"
    }
}
.weatherMobileUpsellPopup {
    position: absolute;
    top: 100%;
    right: calc(25% - 16px);
    width: 440px;
    z-index: 101;
    & > div {
        display: flex;
        margin-top: 7px;
        margin-right: -30px;
        border-radius: 16px;
        background: radial-gradient(1060.97% 141.42% at 0% 0%, #F7E5F0 0%, #EEEBFF 20.44%, #C8D8FF 100%);
        box-shadow: 0px 12px 60px 0px rgba(0, 0, 0, 0.40);
    };
    &::before {
        content: '';
        position: absolute;
        top: 1px;
        right: 16px;
        transform: translate(50%, 0);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 7px solid #f3e8f6;
    }
}
.weatherMobilePopupClose {
    position: relative;
    margin: 12px 15px auto auto;
    height: 12px;
    width: 12px;
    cursor: pointer;
};
.weatherMobileDeviceShell {
    box-shadow: rgba(0, 0, 0, 0.26) 0px 5.85px 11.7px 0px;
    border-radius: 8px;
    width: 116px;
    height: 232px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    margin: 22px auto 22px 37px
};
.weatherMobileDeviceImg {
    width: 104px;
    height: 222px;
    border-radius: 8px;
    display: flex;
    margin: 0 4px 0 4px;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 1.46px 2.93px 0px
};
.weatherMobileUpsellContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px auto 20px 27px;
    gap: 6px;
};
.weatherMobileUpsellHeader {
    color: #000000;
    line-height: 20px;
    font-weight: 600;
    font-size: 20px;
};
.weatherMobileUpsellBody {
    color: rgba(0, 0, 0, 0.8);
    line-height: 18.62px;
    font-weight: 400;
    margin-top: 8px;
    font-size: 14px;
}