/* © Microsoft Corporation. All rights reserved. */

.carouselWrapper {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
}

.carouselContainer {
    overflow-x: hidden;
    display: flex;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    height: 100%;
    &::-webkit-scrollbar {
        display: none;
    }

    &.carouselSquareButton {
        margin: 0 17px;
    }
    &.maskRight {
        -webkit-mask: linear-gradient(
            90deg,
            #c4c4c4 89.46%,
            rgba(196, 196, 196, 0) 97.56%
        );
        mask: linear-gradient(
            90deg,
            #c4c4c4 89.46%,
            rgba(196, 196, 196, 0) 97.56%
        );
    }
    &.maskLeft {
        -webkit-mask: linear-gradient(
            90.08deg,
            rgba(196, 196, 196, 0) 1.65%,
            #c4c4c4 17.54%
        );
        mask: linear-gradient(
            90.08deg,
            rgba(196, 196, 196, 0) 1.65%,
            #c4c4c4 17.54%
        );
    }
}

.carouselItem {
    flex: 0 0 auto;
    scroll-snap-align: start;
}

.carouselChevron {
    position: absolute;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(5px);
    border: none;
    font-size: 1rem;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    z-index: 2;
    padding: 0;
    opacity: 1;
    &:disabled {
        visibility: hidden;
        cursor: auto;
        &.fading {
            visibility: visible;
            opacity: 0.5;
            transition: opacity 0.5s ease-in-out;
        }
    }
    &.carouselSquareButton {
        width: 17px;
        background: none;
        backdrop-filter: none;
        border-radius: 4px;
        &:disabled {
            visibility: visible;
            opacity: 0.5;
            cursor: default;
        }
    }
    &.carouselSolidButton {
        width: 16px;
        height: 32px;
        border-radius: 4px;
        color: #000;
        background-color: #fff;
    }
}

.leftChevron {
    left: -8px;
    transform: rotate(180deg);
    &.carouselSquareButton,
    &.carouselSolidButton {
        left: 0;
    }

    [dir="rtl"] & {
        right: -8px;
        left: auto;
        transform: none;
        &.carouselSquareButton,
        &.carouselSolidButton {
            right: 0;
        }
    }
}

.rightChevron {
    right: -8px;
    &.carouselSquareButton,
    &.carouselSolidButton {
        right: 0;
    }

    [dir="rtl"] & {
        left: -8px;
        right: auto;
        transform: rotate(180deg);
        &.carouselSquareButton,
        &.carouselSolidButton {
            left: 0;
        }
    }
}

.buttonSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    gap: 5px;
    margin-inline-start: 5px;
    & .carouselChevron {
        position: relative;
    }
    & .leftChevron {
        left: initial;
    }
    & .rightChevron {
        right: initial;
    }
}
